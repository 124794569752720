<template>
  <div
    class="border rounded-lg border-bgr-300 px-3 py-2 flex items-center justify-center gap-2 hover:bg-bgr-200 duration-150 hover:cursor-pointer"
    @click="emitProvider"
  >
    <WebccIcon :name="'social/' + icon" class="size-5" filled />
    <span class="font-medium">{{ providerName }}</span>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  icon: string
  providerName: string
  providerCode: string
}>()

const emit = defineEmits<{
  click: [providerCode: string]
}>()

const emitProvider = () => {
  emit('click', props.providerCode)
}
</script>
<style></style>
